/* * {
  padding: 0px;
}
body {
  background-color: #d4d9ed;
}
.cont_principal {
  position: absolute;
  width: 70%;
  height: 100%;
  overflow: hidden;
  right: 0px;
  bottom: 0px;
}
.cont_error {
  position: absolute;
  width: 100%;
  height: 300px;
  top: 50%;
  margin-top: -150px;
}

.cont_error > h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 150px;
  color: #fff;
  position: relative;
  left: -100%;
  transition: all 0.5s;
}

.cont_error > p {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 24px;
  letter-spacing: 5px;
  color: #9294ae;
  position: relative;
  left: 100%;
  transition: all 0.5s;
  transition-delay: 0.5s;
  -webkit-transition: all 0.5s;
  -webkit-transition-delay: 0.5s;
}

.cont_aura_1 {
  position: absolute;
  width: 300px;
  height: 120%;
  top: 25px;
  right: -340px;
  background-color: var(--dominos_blue);
  box-shadow: 0px 0px 60px 20px rgba(100, 112, 222, 0.5);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.cont_aura_2 {
  position: absolute;
  width: 100%;
  height: 300px;
  right: -10%;
  bottom: -301px;
  background-color: var(--dominos_blue);
  box-shadow: 0px 0px 60px 10px rgba(95, 97, 214, 0.5),
    0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 5;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.cont_error_active > .cont_error > h1 {
  left: 0%;
}
.cont_error_active > .cont_error > p {
  left: 0%;
}

.cont_error_active > .cont_aura_2 {
  animation-name: animation_error_2;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform: rotate(-20deg);
}
.cont_error_active > .cont_aura_1 {
  transform: rotate(20deg);
  right: -170px;
  animation-name: animation_error_1;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
} */
/* @-webkit-keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}
@-o-keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}
@-moz-keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}
@keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}

@-webkit-keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}
@-o-keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

@-moz-keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}
@keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
} */

body {
  background-color: #fafafa;
}
.svg-ic {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -400px;
}
.message-box {
  height: 200px;
  width: 380px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: 50px;
  color: var(--dominos_red);
  font-family: 'OpenSans-Regular';
  font-weight: 300;
}
.message-box h1 {
  font-size: 120px;
  line-height: 0px;
  font-family: 'BabasNeueBold';

  /* margin-bottom: 40px; */
}
.buttons-con .action-link-wrap {
  margin-top: 40px;
}
.buttons-con .action-link-wrap a {
  background: #68c950;
  padding: 8px 25px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  margin-right: 10px;
}
.buttons-con .action-link-wrap a:hover {
  background: #5a5c6c;
  color: #fff;
}


#Polygon-1,
#Polygon-2,
#Polygon-3,
#Polygon-4,
#Polygon-4,
#Polygon-5 {
  animation: float 1s infinite ease-in-out alternate;
}
#Polygon-2 {
  animation-delay: 0.2s;
}
#Polygon-3 {
  animation-delay: 0.4s;
}
#Polygon-4 {
  animation-delay: 0.6s;
}
#Polygon-5 {
  animation-delay: 0.8s;
}

@keyframes float {
  100% {
    transform: translateY(20px);
  }
}
@media (max-width: 450px) {
  .svg-ic {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -190px;
  }
  .message-box {
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -190px;
    text-align: center;
  }
}

.slide:hover,
.slide:focus {
  box-shadow: inset 12em 0 0 0 var(--hover);
  transition: 1s;
  color: #fff;
  border-color: transparent !important;
}
.slide {
  --color: var(--dominos_blue);
  --hover: var(--dominos_red);
  background-color: var(--dominos_blue);
}
