/*Dominos Color*/

:root {
  --dominos-red: #d2112c;
  --dominos-blue: #0078ac;
}

.btn-red {
  text-transform: capitalize !important;
  font-family: Open Sans !important;
  font-weight: 600px !important;
  font-size: 14px !important;
  color: #fcfcfc !important;
  text-align: center !important;
  line-height: 1.5 !important;
  border-width: 2px !important;
  height: 40px !important;
  margin: 5px 5px 0px 5px !important;
  background-color: var(--dominos-blue) !important;
}

.gray-background-containers {
  background-color: #eaf0f0;
  margin-top: 25px;
  width: 60%;
  border-radius: 10px;
  align-items: unset;
  margin-left: 35px;
}

.MuiGrid-root.btnRider.MuiGrid-grid-md-6 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}

input#outlined-basics {
  height: 30px;
  font-weight: bold;
  font-size: 18px;
  background-color: white;
}

input#disabled-outlined-basics {
  height: 30px;
  font-weight: bold;
  font-size: 20px;
  background-color: #dddddd;
}
.MuiGrid-root.updatedBy {
  height: 75px !important;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  width: 246px !important;
  top: 333px !important;
}

label#demo-simple-select-filled-label {
  font-family: "Open Sans" !important;
  font-weight: bolder !important;
  font-size: 18px;
  letter-spacing: 1px;
  color: black;
  padding: 0px 10px 0px 2px;
}

div#demo-simple-select-filled {
  font-family: "Open Sans";
  font-weight: 600;
}

div#demo-simple-select-filled {
  background: white;
  font-family: 'OpenSans-Bold';
}