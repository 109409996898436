@import url("./components/ReUseableComponant/FlagistInput/InputFlag.css");
@import url("pages/error/error.css");
@font-face {
  font-family: "OpenSans-Regular";
  src: url("fonts/OpenSans-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-Bold";
  src: url("fonts/OPENSANS-BOLD.TTF");
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("fonts/OPENSANS-SEMIBOLD.TTF");
  font-display: swap;
}
@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url("fonts/OPENSANS-EXTRABOLD.TTF");
  font-display: swap;
}
@font-face {
  font-family: "BabasNeueThin";
  src: url("fonts/BebasNeue\ Thin.otf");
  font-display: swap;
}
@font-face {
  font-family: "BabasNeueLight";
  src: url("fonts/BebasNeue\ Light.otf");
  font-display: swap;
}
@font-face {
  font-family: "BabasNeueBold";
  src: url("fonts/BebasNeue\ Bold.otf");
  font-display: swap;
}

@font-face {
  font-family: "BabasNeueBook";
  src: url("fonts/BebasNeue\ Book.otf");
  font-display: swap;
}
#outlined-basic-on {
  -webkit-text-security: disc;
}
:root {
  --dominos_blue: #0078ac;
  --dominos_red: #d2112c;
  --dominos_white: #fff;
  --dominos_yellow: #ebbc00;
  --dominos-orange: #d16632;
  --dominos-green: #138600;
}
/* 
.fonts-loaded {
  
    font-family: 'OpenSans-Regular';
 
} */
/* remove input type number icons arrows */
/* Chrome, Safari, Edge, Opera */
.my-img {
  width: 40px !important;
  height: auto !important;
  padding-bottom: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  font-family: "OpenSans-Regular", "sans-serif";
}
.mobile-input-background-en.error-red-line {
  font-family: "OpenSans-Regular";
}

.store-input {
  margin-left: 32px !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
span,
a {
  font-family: "OpenSans-Regular", "sans-serif";
}
textarea {
  resize: none;
  font-family: "OpenSans-Regular", "sans-serif";
}
.customSimpleInput {
  border: 0px solid white;
  background-color: white;
  height: 32px;
  font-family: "OpenSans-Regular";
  text-transform: capitalize;
  /* width: 80% !important; */
}
.customSimpleInput:focus {
  outline: none !important;
}
.mobile-container-countrycode {
  background-color: transparent;
  font-family: "OpenSans-Regular";
  border: none;
  color: black;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
}
.test {
  background-color: green;
  width: 2500px !important;
  /* overflow-x: hidden !important; */
}
.task-list {
  /* border-radius: 5px 15px 15px 15px; */
  background: rgba(193, 158, 103, 1);
  background: -moz-linear-gradient(
    left,
    rgba(193, 158, 103, 1) 0%,
    rgba(208, 202, 144, 1) 0%,
    rgba(208, 202, 144, 1) 95%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(193, 158, 103, 1)),
    color-stop(0%, rgba(208, 202, 144, 1)),
    color-stop(95%, rgba(208, 202, 144, 1)),
    color-stop(96%, rgba(255, 255, 255, 1)),
    color-stop(100%, rgba(255, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(193, 158, 103, 1) 0%,
    rgba(208, 202, 144, 1) 0%,
    rgba(208, 202, 144, 1) 95%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(193, 158, 103, 1) 0%,
    rgba(208, 202, 144, 1) 0%,
    rgba(208, 202, 144, 1) 95%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(193, 158, 103, 1) 0%,
    rgba(208, 202, 144, 1) 0%,
    rgba(208, 202, 144, 1) 95%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(193, 158, 103, 1) 0%,
    rgba(208, 202, 144, 1) 0%,
    rgba(208, 202, 144, 1) 95%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c19e67', endColorstr='#ffffff', GradientType=1 );
  width: 93%;
  flex-direction: column;
  display: flex;
  height: 60vh;
  overflow: hidden;
  /* min-height: 70%; */
  /* margin-top: 25px; */
  /* -webkit-border-radius: 5px 15px 15px 15px;
	/* border-radius: 5px 15px 15px 15px; */
  background: rgba(193, 158, 103, 1);
  background: -moz-linear-gradient(
    left,
    rgba(193, 158, 103, 1) 0%,
    rgba(208, 202, 144, 1) 0%,
    rgba(208, 202, 144, 1) 95%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(193, 158, 103, 1)),
    color-stop(0%, rgba(208, 202, 144, 1)),
    color-stop(95%, rgba(208, 202, 144, 1)),
    color-stop(96%, rgba(255, 255, 255, 1)),
    color-stop(100%, rgba(255, 255, 255, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(193, 158, 103, 1) 0%,
    rgba(208, 202, 144, 1) 0%,
    rgba(208, 202, 144, 1) 95%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(193, 158, 103, 1) 0%,
    rgba(208, 202, 144, 1) 0%,
    rgba(208, 202, 144, 1) 95%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(193, 158, 103, 1) 0%,
    rgba(208, 202, 144, 1) 0%,
    rgba(208, 202, 144, 1) 95%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(193, 158, 103, 1) 0%,
    rgba(208, 202, 144, 1) 0%,
    rgba(208, 202, 144, 1) 95%,
    rgba(255, 255, 255, 1) 96%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c19e67', endColorstr='#ffffff', GradientType=1 );
  width: 93%;
  flex-direction: column;
  display: flex;
  height: 46.5vh;
  overflow: hidden;
  /* min-height: 70%; */
  /* margin-top: 25px; */
  /* -webkit-border-radius: 5px 15px 15px 15px;
	-moz-border-radius: 5px 15px 15px 15px;
	-ms-border-radius: 5px 15px 15px 15px;
	-o-border-radius: 5px 15px 15px 15px; */
}
.task-list-button-container {
  margin-top: 20px;
  text-align: center;
  border-radius: 15px;
  height: fit-content;
  width: 90%;
  display: flex;
  padding: 10px;
  justify-content: center;
  flex-direction: row;
  background-color: #ececed;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.task-list-button {
  /* margin-left: 10px; */
  width: 70px;
  height: 70px;
  max-height: 70px;
  background-color: #000000;
  border-radius: 10px;
  padding: 5px;
  padding-top: 15px;
  font-size: 9px;
  color: lightgray;
}
.customer-detail-item {
  width: 89%;
  min-height: 100px;
  background: #aca473;
  color: black;
  border-bottom: 1px solid #aca473;
  border-radius: 15px 15px 0px 0px;
  padding: 10px;
  font-weight: bold;
  font-size: 12px;
  overflow: hidden;
}
/* .customer-detail-item-red{
	width: 89%;
	height: 100px;
	background: linear-gradient(to top left, transparent 50%, var(--dominos_red) 0) top left/15px 15px no-repeat, #aca473;
	color: black;
	border-bottom: 1px solid #aca473;
	border-radius: 0px 15px 0px 0px;
	padding: 10px;
	font-weight: bold;
	font-size: 12px;
	overflow: hidden;
} */
.list-item-delivery {
  margin-top: 25px;
}
.piza-detail-item {
  width: 100%;
  height: auto;
  background: linear-gradient(to top left, transparent 50%, #0477ac 0) top
      left/15px 15px no-repeat,
    #d0ca90;
  color: black;
  border-bottom: 1px solid #aca473;
  /* border-radius: 0px 15px 0px 0px; */
  padding: 10px;
  font-weight: bold;
  font-size: 11px;
  overflow: hidden;
}

.piza-detail-item-finish {
  width: 100%;
  height: auto;
  color: black;
  /* font-weight: bold; */
  font-size: 13px;
  overflow: hidden;
}

.deal-subitems {
  width: 100%;
  /* height: 60px; */
  background: linear-gradient(to top left, transparent 50%, #0477ac 0) top
      left/15px 15px no-repeat,
    rgba(216, 202, 79, 0);
  color: black;
  border-bottom: 1px solid #aca473;
  /* border-radius: 0px 15px 0px 0px; */
  padding: 10px;
  font-weight: bold;
  font-size: 11px;
  overflow: hidden;
}
.deal-item {
  width: 100%;
  /* height: 60px; */
  background: linear-gradient(to top left, transparent 50%, #0477ac 0) top
      left/15px 15px no-repeat,
    #ffe600;
  color: black;
  border-bottom: 1px solid #aca473;
  /* border-radius: 0px 15px 0px 0px; */
  padding: 0 !important;
  font-weight: bold;
  font-size: 11px;
  overflow: hidden;
}
.list-item-price {
  display: flex;
  justify-content: flex-end;
}
.piza-detail-item-selected {
  width: 100%;
  height: 60px;
  background: linear-gradient(to top left, transparent 50%, #d06536 0) top
      left/15px 15px no-repeat,
    #efe7d2;
  color: black;
  border-bottom: 1px solid #aca473;
  /* border-radius: 0px 15px 0px 0px; */
  padding: 10px;
  font-weight: bold;
  font-size: 11px;
  overflow: hidden;
}
.triangle-left {
  margin-left: 5px;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-right: 10px solid #ffffff;
  border-bottom: 30px solid transparent;
}
.deal-triangle-left {
  margin-left: 5px;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-right: 10px solid #ffffff;
  border-bottom: 30px solid transparent;
}
.task-list-footer {
  background-color: #aca473;
  height: 60px;
  width: 89%;
  /* position: absolute; */
  /* bottom: 0; */
  /* top: 10; */
  left: 0;
  border-radius: 0px 0px 15px 15px;
}
.footer-item-one {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0px 10px;
  color: black;
  font-weight: 900 !important;
  font-size: 13px;
}
.pizaListHalfNHalf {
  border-radius: 10px;
  height: 180px;
  color: #fff;
  font-size: 12px;
}
.half-n-half-left {
  background: rgba(209, 102, 50, 1) !important;
  background: -moz-linear-gradient(
    left,
    rgba(209, 102, 50, 1) 0%,
    rgba(209, 102, 50, 1) 50%,
    rgba(0, 126, 176, 1) 50%,
    rgba(0, 126, 176, 1) 100%
  ) !important;
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(209, 102, 50, 1)),
    color-stop(50%, rgba(209, 102, 50, 1)),
    color-stop(50%, rgba(0, 126, 176, 1)),
    color-stop(100%, rgba(0, 126, 176, 1))
  ) !important;
  background: -webkit-linear-gradient(
    left,
    rgba(209, 102, 50, 1) 0%,
    rgba(209, 102, 50, 1) 50%,
    rgba(0, 126, 176, 1) 50%,
    rgba(0, 126, 176, 1) 100%
  ) !important;
  background: -o-linear-gradient(
    left,
    rgba(209, 102, 50, 1) 0%,
    rgba(209, 102, 50, 1) 50%,
    rgba(0, 126, 176, 1) 50%,
    rgba(0, 126, 176, 1) 100%
  ) !important;
  background: -ms-linear-gradient(
    left,
    rgba(209, 102, 50, 1) 0%,
    rgba(209, 102, 50, 1) 50%,
    rgba(0, 126, 176, 1) 50%,
    rgba(0, 126, 176, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(209, 102, 50, 1) 0%,
    rgba(209, 102, 50, 1) 50%,
    rgba(0, 126, 176, 1) 50%,
    rgba(0, 126, 176, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d16632', endColorstr='#007eb0', GradientType=1 );
}
.half-n-half-right {
  background: rgba(0, 126, 176, 1) !important;
  background: -moz-linear-gradient(
    left,
    rgba(0, 126, 176, 0) 0%,
    rgba(0, 126, 176, 0) 50%,
    rgba(209, 102, 50, 1) 50%,
    rgba(209, 102, 50, 1) 100%
  ) !important;
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 126, 176, 0)),
    color-stop(50%, rgba(0, 126, 176, 0.014)),
    color-stop(50%, rgba(209, 102, 50, 1)),
    color-stop(100%, rgba(209, 102, 50, 1))
  ) !important;
  background: -webkit-linear-gradient(
    left,
    rgba(0, 126, 176, 0) 0%,
    rgba(0, 126, 176, 1) 50%,
    rgba(209, 102, 50, 1) 50%,
    rgba(209, 102, 50, 1) 100%
  ) !important;
  background: -o-linear-gradient(
    left,
    rgba(0, 126, 176, 1) 0%,
    rgba(0, 126, 176, 1) 50%,
    rgba(209, 102, 50, 1) 50%,
    rgba(209, 102, 50, 1) 100%
  ) !important;
  background: -ms-linear-gradient(
    left,
    rgba(0, 126, 176, 1) 0%,
    rgba(0, 126, 176, 1) 50%,
    rgba(209, 102, 50, 1) 50%,
    rgba(209, 102, 50, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 126, 176, 1) 0%,
    rgba(0, 126, 176, 1) 50%,
    rgba(209, 102, 50, 1) 50%,
    rgba(209, 102, 50, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007eb0', endColorstr='#d16632', GradientType=1 );
}
.scrollable-container {
  background-color: white;
  padding: 5px 20px;
  border-radius: 5px;
  width: 100%;
  height: 270px;
  overflow-y: scroll;
  border: 1px solid lightgray;
}
/* scrollBar styling start  */
.scrollable-container::-webkit-scrollbar {
  width: 60px;
}
.scrollable-container::-webkit-scrollbar-button:single-button {
  background-color: black;

  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}

.scrollable-container::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 42px;
  width: 16px;
  background-position: center 12px;
  background-size: 30px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
}
.scrollable-container::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 42px;
  width: 16px;
  background-position: center 15px;
  background-size: 30px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(73, 73, 73)'><polygon points='0,0 100,0 50,50'/></svg>");
}
.scrollable-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* border: 1px solid black; */
  border-radius: 0px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: lightgray;
  border: 1px solid gray;
  border-radius: 00px;
}
/* scrollBar styling End  */

.input-Slect-material > div > input {
  padding-left: 5px !important;
}
.input-Slect-material > div::before {
  border-bottom: none !important;
}
.input-Slect-material > div::after {
  border-bottom: none !important;
}
.input-Slect-material > div:focus {
  background-color: #fff !important;
}
.gray-background-container {
  background-color: #eaf0f0;
  padding: 20px;
  border-radius: 10px;
  margin-top: 5px;
  width: 100%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.slect-no-design {
  padding-left: 8px;
}
.slect-no-design::before {
  border-bottom: none !important;
}
.slect-no-design::after {
  border-bottom: none !important;
}
.slect-no-design > div:focus {
  background-color: white !important;
}
.my-searchbox-new {
  position: absolute !important;
  z-index: 100 !important;
}
.search-place-result {
  position: absolute;
  cursor: pointer;
  border: 1.1px double bisque;
  padding: 0.5em;
  background-color: white;
  list-style: none;
  margin-top: auto;
  border-top: 1px solid lightgray;
}
.map-location-head {
  background-color: white !important;
  width: 500px !important;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  height: 40px;
  padding: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
}

.topping-head {
  font-family: "BabasNeueBold";
  text-transform: uppercase;
}
.input-fields-logo {
  width: 50px;
  height: 50px;
  margin-top: -4px;
}

/* FOR H&H */

.backgroung-blue {
  background: var(--dominos_blue) !important;
}
.backgroung-orange {
  background: var(--dominos-orange) !important;
}
.backgroung-orange-hnh {
  background: rgba(209, 102, 50, 1) !important;
}
.error-red-box {
  border: 1px solid red !important;
}
.error-red-line {
  border-bottom: 1px solid red !important;
}

.toppings-container {
  display: flex;
  flex-direction: row;
}
.expansion-text {
  font-size: 13px;
  color: #a4a4a4 !important;
  font-family: "OpenSans-Regular", "sans-serif";
}
.price-text-strike {
  color: grey;
  text-decoration: line-through;
  /* font-family: 'BabasNeueBold'; */
  font-size: 0.875rem;
}
.price-text {
  color: #4a4a4a;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
}
#text-area {
  font-size: 12px;
  color: black;
  padding-left: 7px;
}
#text-area-delivery {
  font-size: 18px;
  color: black;
  padding-left: 7px;
}
.text-area-deliveryInstructions > .MuiInput-underline:before {
  border-bottom: none !important;
}
.text-area-deliveryInstructions > .MuiInput-underline:after {
  border-bottom: none !important;
}
.text-qty-value > .MuiInput-underline:before {
  border-bottom: none !important;
}
.text-qty-value > .MuiInput-underline:after {
  border-bottom: none !important;
}
#text-qty {
  font-size: 25px;
  color: black;
  padding: 14px 0px;
  text-align: center;
}
button#btnblock {
  margin-left: 19px;
  height: 87px;
}

.Toastify__toast--success {
  background: rgb(0, 120, 172) !important;
}
.Toastify__toast--warning {
  background: var(--dominos_yellow) !important;
}
.Toastify__toast--error {
  background: var(--dominos_red) !important;
}
.Toastify__toast-body {
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: "OpenSans-Regular" !important;
}

.scrollable-container-2 {
  background-color: white;
  padding: 5px 20px;
  border-radius: 5px;
  width: 100%;
  height: 270px;
  overflow-y: scroll;
  border: 1px solid lightgray;
}
/* scrollBar styling start  */
.scrollable-container-2::-webkit-scrollbar {
  width: 60px;
}
.scrollable-container-2::-webkit-scrollbar-button:single-button {
  background-color: black;

  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}

.scrollable-container-2::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 42px;
  width: 16px;
  background-position: center 12px;
  background-size: 30px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
}
.scrollable-container-2::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 42px;
  width: 16px;
  background-position: center 15px;
  background-size: 30px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(73, 73, 73)'><polygon points='0,0 100,0 50,50'/></svg>");
}
.scrollable-container-2::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* border: 1px solid black; */
  border-radius: 0px;
}

.scrollable-container-2::-webkit-scrollbar-thumb {
  background: lightgray;
  border: 1px solid gray;
  border-radius: 00px;
}

/* scrollBar styling End  */
.btnCheck > span {
  justify-content: left;
}
#search-coupon {
  /* background-color: var(--dominos_blue) !important; */
  color: var(--dominos_blue) !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
  text-transform: uppercase !important;
}

#search-coupon::placeholder {
  /* text-transform: capitalize !important; */
  color: var(--dominos_blue) !important;
  opacity: 1 !important;
  font-size: 12px !important;
  text-align: center !important;
}
.search-coupon-code > .MuiOutlinedInput-root {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}
.para-style {
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
}

.float {
  position: fixed;
  z-index: 99999;
  display: flex;
  left: 14.5px;
  height: 60px;
  background-color: var(--dominos_red);
  color: #fff;
  border-radius: 12px;
  text-align: center;
  font-size: 26px;
  bottom: 104px;
  align-items: center;
  justify-content: center;
  font-family: "OpenSans-Regular";
}
.react-stopwatch-timer__table {
  height: 40px !important;
  margin-top: -10px;
}

.gmnoprint {
  display: none !important;
}

.map-container > :nth-child(2) {
  position: unset !important;
}

.pulseAddress {
  margin-left: 52px !important;
}

.pulseAddressLabel > form > label > div > div > p {
  font-weight: bold !important;
  margin-top: 0px !important;
  margin-left: 5px !important;
  margin-bottom: 0px !important;
  width: 100px !important;
}

p.deliveryError {
  background: red;
  width: 300px;
  padding: 5px;
  color: white;
  text-transform: uppercase;
}

.tabHeading1 > span {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-family: "BabasNeueBold";
  font-size: 28px;
}
.css-1hwfws3 {
  cursor: text !important;
}
.mobile-input-background-en {
  margin-left: 20px !important;
}

/*Agent Management*/
.res-container {
  padding: 20px 50px 20px 50px !important;
}

.padding-con {
  padding: 20px 30px 20px 30px !important;
}

.blue-background {
  background: var(--dominos_blue) !important;
  border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  -o-border-radius: 5px 5px 0 0;
  height: 50px !important;
}

.blue-background > h2 {
  color: white !important;
  font-family: "Bold" !important;
  text-transform: uppercase !important;
  font-size: 35px !important;
}

.blue-background > .MuiCardHeader-content > span {
  color: white !important;
  font-family: "Bold" !important;
  text-transform: uppercase !important;
  font-size: 35px !important;
}

.blue-background
  .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  color: white !important;
  font-family: "OpenSans-Regular";
  font-weight: 700 !important;
}

.blue-background > .MuiCardHeader-content > span {
  font-size: 24px !important;
}

.MuiCardHeader-root.blue-background div {
  font-family: "OpenSans-Regular";
  font-weight: 900 !important;
}

h6#total-coupons {
  color: var(--dominos_blue) !important;
  font-family: "OpenSans-Regular";
  text-transform: uppercase !important;
  font-size: 20px !important;
  font-weight: 900 !important;
  margin-top: 22px;
}

div#table-ui-agent > div > div > div {
  max-height: 60px !important;
}

div#table-ui-agent > div > div > table > thead > tr > th {
  background-color: var(--dominos_blue) !important;
  color: white !important;
  z-index: 110 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

div#table-ui-agent
  > div
  > div
  > table
  > thead
  > tr
  > th
  > span
  > button
  > span {
  color: white !important;
  z-index: 110 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.btn-red-outlined {
  margin: 0px 5px !important;
  background-color: white !important;
  text-transform: capitalize !important;
  font-family: Open Sans !important;
  font-weight: 600px !important;
  font-size: 14px !important;
  color: var(--dominos_red) !important;
  border: 2px solid var(--dominos_red) !important;
  text-align: center !important;
  line-height: 1.5 !important;
  height: 40px !important ;
}

.btn-gray-outlined {
  margin: 0px 5px !important;
  text-transform: capitalize !important;
  font-family: Open Sans !important;
  font-weight: 600px !important;
  font-size: 14px !important;
  color: #a4a4a4 !important;
  border: 2px solid rgb(235, 235, 228) !important;
  background-color: rgb(235, 235, 228) !important;
  text-align: center !important;
  line-height: 1.5 !important;
  height: 40px !important ;
}

button.btn-red-outlined:hover {
  color: white !important;
  background: var(--dominos_red) !important;
}

.MUIDataTableToolbar-icon-475:hover {
  color: var(--dominos_blue);
}

.MuiCardHeader-root.blue-background > div > span {
  font-family: "OpenSans-Regular" !important;
  font-weight: 900 !important;
}

input#agentTxt {
  font-size: 16px;
}

div#agentTxt {
  color: black;
  font-weight: 500;
  font-size: 15px;
}

label#agentTxt-label {
  font-weight: 700 !important;
  font-family: "OpenSans-Regular" !important;
  color: black !important;
  background-color: white !important;
  font-size: 18px;
}

.btn-blue-outlined {
  margin-left: 10px !important;
  background-color: white !important;
  text-transform: capitalize !important;
  font-family: Open Sans !important;
  font-weight: 600px !important;
  font-size: 14px !important;
  color: var(--dominos-blue) !important;
  border: 2px solid var(--dominos-blue) !important;
  text-align: center !important;
  line-height: 1.5 !important;
  height: 40px !important ;
}

.btn-blue-outlined:focus {
  outline: 1px dotted !important;
  outline: 5px auto var(--dominos-blue) !important;
}

.btn-blue-outlined:hover {
  text-transform: capitalize !important ;
  font-family: Open Sans !important ;
  font-weight: 600px !important ;
  font-size: 14px !important ;
  color: #ffffff !important ;
  text-align: center !important ;
  line-height: 1.5 !important ;
  border-width: 2px !important ;
  height: 40px !important ;
  margin-left: 8px !important;
  transition: 0.5s;
  background-color: var(--dominos-blue) !important;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: white !important;
}

.MuiSwitch-colorSecondary .MuiIconButton-label {
  color: #fff !important;
}

.MuiSwitch-colorSecondary.Mui-checked .MuiIconButton-label {
  color: #fff !important;
}

span.MuiSwitch-root.switchBtn {
  margin-right: 10px;
}

.MuiGrid-root.buttonRight.MuiGrid-item.MuiGrid-grid-xs-12 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
}

.promo-btn-styling {
    background: var(--dominos_blue) !important;
    color: rgb(255 255 255) !important;
    font-family: "BabasNeueLight" !important;
    font-weight: bolder !important;
    letter-spacing: 3px !important;
    padding: 5px !important;
    text-align: left !important;
}

.blinking {
  animation: blinking 1.5s infinite;
}

@keyframes blinking{
    0%{     color: #ffffff;    }
    49%{    color: #ffffff; }
    60%{    color: var(--dominos_red); }
    99%{    color: var(--dominos_red);  }
    100%{   color: #ffffff;    }
}

.nearToExpiredCoupon {
  border: 2px solid red !important;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px !important;
  border-radius: 0px !important;
}

.coupons {
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px !important;
  border: none !important;
  border-radius: 0px !important;
}

.disabledCoupon {
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px !important;
  border: none !important;
  border-radius: 0px !important;
  opacity: 0.5 !important;
}

.MuiCardContent-root:last-child {
  padding-bottom: 13px !important;
}

div#wrapped-tabpanel-0 div.MuiBox-root , div#wrapped-tabpanel-1 div.MuiBox-root {
  padding: 12px 24px 24px 24px !important;
}

.tabs-header {
  display: flex;
  padding: 16px 16px 0px 16px;
  align-items: center;
}

.nearToExpiredDisabledCoupon {
  border: 2px solid red !important;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px !important;
  border-radius: 0px !important;
  opacity: 0.5 !important;
}