.mobile-input-background-az {
  /* background-image: url('../../uploads/background/mobile/mobile-container-az.png'); */
  background-color: white;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  position: relative;
  display: flex;
}
.mobile-input-background-ru {
  /* background-image: url('../../uploads/background/mobile/mobile-container-ru.png'); */
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  position: relative;
  display: flex;
}
.mobile-input-background-en {
  background-color: white;
  /* background-image: url('../../uploads/background/mobile/mobile-container.png'); */
  /* height: 75px; */
  background-size: contain;
  width: 100%;
  background-repeat: no-repeat;
  /* background-color: transparent; */
  position: relative;
  display: flex;
  /* padding-top: 8px; */
}
.mobile-container {
  display: flex;
  align-items: center;
  width: 330px;
  padding-left: 5px;
  padding-right: 5px;
}

.mobile-container-flag {
  height: 30px;
  object-fit: contain;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
}

.mobile-container-caret {
  height: 10px;
  object-fit: contain;
  padding-left: 2px;
  padding-right: 2px;
}

.mobile-container-input-container,
.mobile-container-input {
  flex: 1;
  width: 100%;
  
  /* padding-top: 2.5px; */
}

.mobile-container-input {
  background-color: transparent;
  border: none;
  color: black;
  padding-top: 10px;
  /* font-size: 18px; */
}
.mobile-container-input:focus,
.mobile-container-input:active {
  border: none !important;
  outline: none !important;
}
.mobile-container-countrycode {
  background-color: transparent;
  font-family: 'Open Sans';
  border: none;
  color: black;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
}
.flag-item {
  background-color: white;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.flag-item:hover {
  background-color: whitesmoke;
}
.mobile-list {
  height: 470px;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* .mobile-container-search{
    background-color: transparent;
    border: none;
    color: rgb(0, 0, 0);
    font-size: 18px;
    w
} */
.mobile-list-width {
  padding: 0px !important;
  width: 100%;
  min-width: 433px;
}
.mobile-flag-icon {
  height: 30px;
  object-fit: contain;
  margin-right: 10px;
  filter: drop-shadow(rgb(0, 0, 0) 0px 1px 2px);
}

@media screen and (max-width: 720px) and (orientation: portrait) {
  .mobile-list {
    height: calc(100% - 95px) !important;
  }
  .mobile-list-width {
    min-width: 100% !important;
  }
}
.cursor-pointer {
  cursor: pointer;
  display: flex;
    align-content: center;
  /* padding-top: 5px; */
}
.cursor-pointer-caret {
  cursor: pointer;
  padding-top: 2.5px;
}

.input-container {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.icon {
  padding: 10px;
  background: dodgerblue;
  color: white;
  min-width: 50px;
  text-align: center;
}

.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}
